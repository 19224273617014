import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Section from '../components/Atoms/Section';
import Container from '../components/Atoms/Container';
import Paragraph from '../components/Atoms/Paragraph';
import Row from '../components/Atoms/Row';
import Column from '../components/Atoms/Column';
import Box from '../components/Atoms/Box';
import Heading from '../components/Atoms/Heading';

const NotFoundPage = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Column my={8}>
            <Box textAlign="center" py={7} px={5} background="#f3f9f8" borderRadius={2}>
              <Heading>
                <FormattedHTMLMessage id="notFound.title" defaultMessage="Page not found" />
              </Heading>
              <Paragraph>
                <FormattedHTMLMessage id="notFound.content" defaultMessage="The page you are looking for cannot be found." />
              </Paragraph>
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  </>
);

export default NotFoundPage;
